import * as types from "./constants";
import { request } from "../redux/api";
import { URL_API } from "../core/urlsApi";
import store from "./store";

/* ==========================================================================
    USERS
========================================================================== */

export async function login(data) {
  const { LOGIN, LOGIN_SUCCESS, LOGIN_ERROR } = types;
  const login = await request(
    URL_API + "/login/",
    "POST",
    [LOGIN, LOGIN_SUCCESS, LOGIN_ERROR],
    data
  );
  // console.log('login', login);
  return login;
}

export async function registerUSER(data) {
  const { REGISTER, REGISTER_SUCCESS, REGISTER_ERROR } = types;
  const register = await request(
    URL_API + "/users/register/",
    "POST",
    [REGISTER, REGISTER_SUCCESS, REGISTER_ERROR],
    data
  );
  // console.log('register', register);
  return register;
}

export async function updateUser(data) {
  const { UPDATEUSER, UPDATEUSER_SUCCESS, UPDATEUSER_ERROR } = types;
  const updated = await request(
    URL_API + "/users/update/",
    "POST",
    [UPDATEUSER, UPDATEUSER_SUCCESS, UPDATEUSER_ERROR],
    data,
    store.getState().user.token
  );
  console.log(updated);
  return updated;
}

export async function updateRole(roles) {
  const { UPDATEROLE, UPDATEROLE_SUCCESS, UPDATEROLE_ERROR } = types;
  const updatedRole = await request(
    URL_API + "/users/update/role/",
    "POST",
    [UPDATEROLE, UPDATEROLE_SUCCESS, UPDATEROLE_ERROR],
    roles,
    store.getState().user.token
  );
  console.log(updatedRole);
  return updatedRole;
};

export async function updatePassword(data) {
  const newpass = await request(
    URL_API + "/users/update/password/",
    "POST",
    ['UPDATEPASSWORD', 'UPDATEPASSWORD_SUCCESS', 'UPDATEPASSWORD_ERROR'],
    data,
    store.getState().user.token
  )
  return newpass;
}

export async function getUSERS() {
	const { GET_USERS, GET_USERS_SUCCESS, GET_USERS_ERROR } = types;
	const users = await request(
		URL_API + "/users/all/",
		"GET",
		[GET_USERS, GET_USERS_SUCCESS, GET_USERS_ERROR],
		"",
		store.getState().user.token
	);
	return users;
}

export async function sendRecoverMail(data) {
  const users = await request(
    URL_API + "/users/recover/",
    "POST",
    ['SENDMAIL', 'SENDMAIL_SUCCESS', 'SENDMAIL_ERROR'],
    data
  );
  return users;
}

export async function validateToken(data) {
  const change = await request(
    URL_API + "/users/validatetoken/",
    "POST",
    ['CHANGEPASSWORD', 'CHANGEPASSWORD_SUCCESS', 'CHANGEPASSWORD_ERROR'],
    data
  )
  return change;
}

export async function renderChangePassword(data) {
  const change = await request(
    URL_API + "/users/restorepassword/",
    "POST",
    ['CHANGEPASSWORD', 'CHANGEPASSWORD_SUCCESS', 'CHANGEPASSWORD_ERROR'],
    data
  )
  return change;
}

/* ==========================================================================
    TIMEZONES
========================================================================== */

export async function getUTC() {
  const { GET_UTC, GET_UTC_SUCCESS, GET_UTC_ERROR } = types;
  const utc = await request(URL_API + "/utc/", "GET", [
    GET_UTC,
    GET_UTC_SUCCESS,
    GET_UTC_ERROR
  ]);
  // console.log('utc', utc);
  return utc;
}

/* ==========================================================================
    NATIONALITIES
========================================================================== */

export async function getNAT() {
  const {
    GET_NATIONALITIES,
    GET_NATIONALITIES_SUCCESS,
    GET_NATIONALITIES_ERROR
  } = types;
  const nationalities = await request(URL_API + "/nationalities/", "GET", [
    GET_NATIONALITIES,
    GET_NATIONALITIES_SUCCESS,
    GET_NATIONALITIES_ERROR
  ]);
  // console.log('topics', topics);
  return nationalities;
}

/* ==========================================================================
    TOPICS
========================================================================== */

export async function getTOPICS() {
  const { GET_TOPICS, GET_TOPICS_SUCCESS, GET_TOPICS_ERROR } = types;
  const topics = await request(URL_API + "/topics/", "GET", [
    GET_TOPICS,
    GET_TOPICS_SUCCESS,
    GET_TOPICS_ERROR
  ]);
  // console.log('topics', topics);
  return topics;
}

/* ==========================================================================
    SESSIONS
========================================================================== */

export async function getSESSIONS(data) {
	const { GET_SESSIONS, GET_SESSIONS_SUCCESS, GET_SESSIONS_ERROR } = types;
	const sessions = await request(
		URL_API + "/sessions/",
		"GET",
		[GET_SESSIONS, GET_SESSIONS_SUCCESS, GET_SESSIONS_ERROR],
		data,
		store.getState().user.token
	);
	return sessions;
}

export async function reserveSESSIONS(data) {
	const {
		RESERVE_SESSIONS,
		RESERVE_SESSIONS_SUCCESS,
		RESERVE_SESSIONS_ERROR
	} = types;
	const reserve = await request(
		URL_API + "/sessions/reserve/",
		"POST",
		[RESERVE_SESSIONS, RESERVE_SESSIONS_SUCCESS, RESERVE_SESSIONS_ERROR],
		data,
		store.getState().user.token
	);
	return reserve;
}

export async function getSESSIONSByUser(data) {
	const {
		GET_USER_SESSIONS,
		GET_USER_SESSIONS_SUCCESS,
		GET_USER_SESSIONS_ERROR
	} = types;
	const reserve = await request(
		URL_API + "/sessions/getbyuser/",
		"POST",
		[GET_USER_SESSIONS, GET_USER_SESSIONS_SUCCESS, GET_USER_SESSIONS_ERROR],
		data,
		store.getState().user.token
	);
	// console.log('sessions', sessions);
	return reserve;
};

export async function getNextSession(data) {
	const { GET_NEXTCLASS, GET_NEXTCLASS_SUCCESS, GET_NEXTCLASS_ERROR } = types;
	const nextClass = await request(
		URL_API + "/sessions/getnextclass",
		"GET",
		[GET_NEXTCLASS, GET_NEXTCLASS_SUCCESS, GET_NEXTCLASS_ERROR],
		data,
		store.getState().user.token
	);
	// console.log('nextClass', nextSesion);
	return nextClass;
};

export async function updateComments(data) {
	const { UPDATE_COMMENTS, UPDATE_COMMENTS_SUCCESS, UPDATE_COMMENTS_ERROR } = types;
	const updatedComments = await request(
		URL_API + "/sessions/updatecomments/",
		"POST",
		[UPDATE_COMMENTS, UPDATE_COMMENTS_SUCCESS, UPDATE_COMMENTS_ERROR],
		data,
		store.getState().user.token
	);
	//console.log(updatedComments);
	return updatedComments;
};

export async function updateCommentsStudent(data) {
	const { UPDATE_COMMENTS, UPDATE_COMMENTS_SUCCESS, UPDATE_COMMENTS_ERROR } = types;
	const updatedComments = await request(
		URL_API + "/sessions/updatecomments_student/",
		"POST",
		[UPDATE_COMMENTS, UPDATE_COMMENTS_SUCCESS, UPDATE_COMMENTS_ERROR],
		data,
		store.getState().user.token
	);
	//console.log(updatedComments);
	return updatedComments;
};

/* ==========================================================================
    CREDITS
========================================================================== */

export function optionPaySelected(optionPay) {
	const { OPTIONPAY } = types;
	return {
		type: OPTIONPAY,
		data: optionPay
	};
}

export async function getCredits(data) {
  const { GET_CREDITS, GET_CREDITS_SUCCESS, GET_CREDITS_ERROR } = types;
  const reserve = await request(
    URL_API + "/credits/",
    "GET",
    [GET_CREDITS, GET_CREDITS_SUCCESS, GET_CREDITS_ERROR],
    data,
    store.getState().user.token
  );
  // console.log('sessions', sessions);
  return reserve;
}

export async function buyCredits(data) {
  const { BUY_CREDITS, BUY_CREDITS_ERROR, BUY_CREDITS_SUCCESS } = types;
  const puchase = await request(
    URL_API + "/credits/buy",
    "POST",
    [BUY_CREDITS, BUY_CREDITS_ERROR, BUY_CREDITS_SUCCESS],
    data,
    store.getState().token
  );
  return puchase;
}

/*Credits Admin*/
export async function getCreditsAdmin(data) {
  const { GET_CREDITS, GET_CREDITS_SUCCESS, GET_CREDITS_ERROR } = types;
  const reserve = await request(
    URL_API + "/credits/admin/",
    "GET",
    [GET_CREDITS, GET_CREDITS_SUCCESS, GET_CREDITS_ERROR],
    data,
    store.getState().user.token
  );
  // console.log('sessions', sessions);
  return reserve;
}

/* ==========================================================================
    TEACHERS
========================================================================== */
export async function getTeachers(data) {
  const { GET_TEACHERS, GET_TEACHERS_SUCCESS, GET_TEACHERS_ERROR } = types;
  const teachers = await request(
    URL_API + "/teachers/",
    "GET",
    [GET_TEACHERS, GET_TEACHERS_SUCCESS, GET_TEACHERS_ERROR],
    data,
    store.getState().user.token || 'guest'
  );
  return teachers;
}

export async function getCalendars(data) {
  const { GET_CALENDARS, GET_CALENDARS_SUCCESS, GET_CALENDARS_ERROR } = types;
  const calendars = await request(
    URL_API + "/teachers/calendars",
    "GET",
    [GET_CALENDARS, GET_CALENDARS_SUCCESS, GET_CALENDARS_ERROR],
    data,
    store.getState().user.token
  );
  // console.log('calendars', calendars);
  return calendars;
}

export async function newCalendar(data) {
  const { ADD_CALENDARS, ADD_CALENDARS_SUCCESS, ADD_CALENDARS_ERROR } = types;
  const calendars = await request(
    URL_API + "/teachers/calendars/add",
    "POST",
    [ADD_CALENDARS, ADD_CALENDARS_SUCCESS, ADD_CALENDARS_ERROR],
    data,
    store.getState().user.token
  );
  // console.log('calendars', calendars);
  return calendars;
}

export async function delCalendar(data) {
  const { DEL_CALENDAR, DEL_CALENDAR_SUCCESS, DEL_CALENDAR_ERROR } = types;
  const calendars = await request(
    URL_API + "/teachers/calendars/del",
    "POST",
    [DEL_CALENDAR, DEL_CALENDAR_SUCCESS, DEL_CALENDAR_ERROR],
    data,
    store.getState().user.token
  );
  // console.log('calendars', calendars);
  return calendars;
}

export async function saveCalendar(data) {
  const { SAVE_CALENDAR, SAVE_CALENDAR_SUCCESS, SAVE_CALENDAR_ERROR } = types;
  const calendars = await request(
    URL_API + "/teachers/calendars/save",
    "POST",
    [SAVE_CALENDAR, SAVE_CALENDAR_SUCCESS, SAVE_CALENDAR_ERROR],
    data,
    store.getState().user.token
  );
  // console.log('calendars', calendars);
  return calendars;
}

export async function getHours(data) {
  const { GET_HOURS, GET_HOURS_SUCCESS, GET_HOURS_ERROR } = types;
  const calendars = await request(
    URL_API + "/teachers/hours",
    "GET",
    [GET_HOURS, GET_HOURS_SUCCESS, GET_HOURS_ERROR],
    data,
    store.getState().user.token
  );
  // console.log('calendars', calendars);
  return calendars;
}

export async function getCLASSEST(data) {
	const { GET_CLASSES_TEACHER, GET_CLASSES_TEACHER_SUCCESS, GET_CLASSES_TEACHER_ERROR } = types;
	const classes = await request(
		URL_API + "/teachers/classes",
		"GET",
		[GET_CLASSES_TEACHER, GET_CLASSES_TEACHER_SUCCESS, GET_CLASSES_TEACHER_ERROR],
		data,
		store.getState().user.token
	);
	// console.log('clasess', classes);
	return classes;
}
