import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import WebFont from 'webfontloader';
import 'react-app-polyfill/ie11';

// Stylesheet
import './css/style.css';
// import './sass/style.scss';
import 'js-datepicker/dist/datepicker.min.css';

WebFont.load({
   google: {
      families: ['Dosis:400,500,600,700,800', 'sans-serif'],
   },
});

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.unregister();
