/**
 *  httpClient.js
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Cliente HTTP para todas las peticiones Web
*/

import request from "superagent";
import { URL_API } from './urlsApi';

//version web
const version = process.env.REACT_APP_CACHE_BUST || 'test';
console.log('version', version);

class Request {

    get(url, data) {
        const result = request
            .get(URL_API + url)
            .query(data)
			.set({ 'auth': '5fcae9a7be1dfa53da44c353492bc73d' })
			.set({ 'version': version })
            .then(res => {
                return res.body;
            })
            .catch(err => {
                //console.log(err.message);
                return { error: true, message: err.message };
            });
        return result;
    }

    post(url, data) {
		const result = request
			.post(URL_API + url)
            .send(data)
			.set({ 'auth': '5fcae9a7be1dfa53da44c353492bc73d' })
			.set({ 'version': version })
            .then(res => {
                return res.body;
            })
            .catch(err => {
                //console.log(err.message);
                return { error: true, message: err.message };
            });
        return result;
    }

    postFile(url, data, formdata) {
		const result = request
			.post(URL_API + url)
            // .send(data)
			.accept('application/json')
			.set({ 'version': version })
            .set('Content-Type', 'multipart/form-data')
            .set("Content-Type", "application/octet-stream")
			.set({ 'igate_key': '5fcae9a7be1dfa53da44c353492bc73d' })
            .attach(formdata)
            .type('form')

            .then(res => {
                return res.body;
            })
            .catch(err => {
                //console.log(err.message);
                return { error: true, message: err.message };
            });
            // .set("Content-Type", "application/octet-stream")

        return result;
    }
}

export default Request;
