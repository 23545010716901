import React from 'react';
import { Link } from 'react-router-dom';
import Faqs from '../files/BC_Faqs.pdf';

// Images
import Images from '../img/index';

const table = [
   { head: 'Credit', rows: ['30 minutes', '60 minutes', '120 minutes', '240 minutes'] },
   { head: 'Price', rows: ['21.00€', '37.00€', '74.00€', '148.00€'] },
   { head: 'Discount', rows: ['', '', '6%', '10%'] },
   { head: 'NOW!', rows: ['21.00€', '37.00€', '70.00€', '133.00€'] },
];

const Pricing = () => (
   <div className="introduction justify-center">
      <div className="container">
         <form className="column">
            <div className="row-responsive">
               <div className="left column">
                  <img src={Images['testimonial_5']} alt="Hello" title="Hello" />
               </div>

               <div className="right column">
                  <div className="white-space-16"></div>
                  <h1 className="color-orange" id="to-pricing">
                     PRICING
                  </h1>
                  <div className="white-space-32"></div>
                  <div className="table-responsive">
                     <table className="table-price">
                        <thead>
                           <tr>
                              {table.map((item, index) => (
                                 <th key={index}>{item.head}</th>
                              ))}
                           </tr>
                        </thead>
                        <tbody>
                           {table
                              .reduce((acc, item, index) => {
                                 item.rows.forEach((row, i) => {
                                    if (!acc[i]) {
                                       acc[i] = [];
                                    }
                                    acc[i].push(row);
                                 });

                                 return acc;
                              }, [])
                              .map((row, index) => (
                                 <tr
                                    key={index}
                                    className={index === 1 ? 'color-orange' : ''}
                                 >
                                    {row.map((cell, i) => (
                                       <td key={i}>{cell}</td>
                                    ))}
                                 </tr>
                              ))}
                        </tbody>
                     </table>
                  </div>
                  <div className="white-space-32"></div>
                  <a href={Faqs} className="link">
                     Need help? Check the FAQ's
                  </a>
                  <div className="white-space-16"></div>
                  <div className="justify-between row-responsive" id="pricing-buttons">
                     <div className="btn-container auto">
                        <Link to="/register" className="btn btn-orange">
                           <i className="fas fa-plus-circle"></i>&nbsp; CREATE AN ACCOUNT
                        </Link>
                     </div>
                     <div className="btn-container auto">
                        <Link to="/login" className="btn btn-orange">
                           <i className="fas fa-user"></i>&nbsp; ACCESS NOW
                        </Link>
                     </div>
                  </div>
               </div>
            </div>
            <div className="white-space-16"></div>
         </form>
      </div>
   </div>
);

export default Pricing;
