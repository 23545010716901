import React, { Component } from "react";
import moment from 'moment-timezone';
import { URL_API } from "../../../core/urlsApi";

// Images
import ImageLoader from "react-loading-image";

class Teachers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  imgError(event) {
    event.target.src =
      "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/600px-No_image_available.svg.png";
  }

  render() {
    let sessions = [];
    if (this.props.sessions) {
      sessions = this.props.sessions.slice(((this.props.page - 1) * 6), (this.props.page * 6));
    }    
    return (      
      <div className="column">
        {sessions.map((session, index) => {
          const image = URL_API + "/users/image/?idImage=" + session.IDIMAGE;
          return (
          <div className="avaible-sesions-list column" key={index}>
            <div className="avaible-sesions-item row-responsive">
              <div className="teacher-picture auto">
                <ImageLoader
                  src={ image }
                  image={ props => <img src={props.src} alt="Teacher"/>}
                    loading={() => <div className="small-spinner justify-center align-center">
                      <i className="fas fa-spinner fa-spin" />
                    </div>}
                  onError={this.imgError.bind(this)} id="profile-img" />
                  {/*<img src="https://secure.moneypak.com/cmsfolder/content/images/ring-alt_1.gif" alt="loading"/>*/}
              </div>
              <div className="teacher-data column justify-center">
                <p className="color-orange weight-bold">{session.TEACHER}</p>
                <div className="white-space-8" />
                <div className="row schedule">
                  <p className="text-justify">
                    Day: &nbsp;
                    <span className="color-orange weight-medium">
                    {moment(session.DIA).tz(this.props.timezone).format('YYYY/MM/DD')}
                    </span>
                  </p>
                  <p className="text-justify">
                    Start Time: &nbsp;
                    <span className="color-orange weight-medium">
                    {moment(session.INI).tz(this.props.timezone).format('HH:mm a')}
                    </span>
                  </p>
                  <p className="text-justify">
                    Finish Time: &nbsp;
                    <span className="color-orange weight-medium">
                    {moment(session.FIN).tz(this.props.timezone).format('HH:mm a')}
                    </span>
                  </p>
                </div>
              </div>
              <div className="teacher-book auto">
                <button className="btn btn-orange" data-open="book" onClick={this.appearModal.bind(this, session)} >
                  <i className="fas fa-plus-square" />
                  &nbsp; BOOK NOW
                  </button>
              </div>
            </div>
          </div>
        )})}  
      </div>
    );
  }

  appearModal(session) {
    console.log(session)
    if(moment(session.INI).diff(moment(), 'hours') < 24) {
      alert('SORRY YOU CAN ONLY BOOK SESSIONS BEFORE 24 HOURS')
    } else {
      this.props.setSession(session);
      document.getElementById("modal-book").classList.remove("close-modal");
      document.getElementById("modal-book").classList.remove("hidden");
    }
  }
}

export default Teachers;
